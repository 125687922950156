// {
//   youtubeLink: "",
//   youtubeTitle:"",
//   description: "",
//   lat: ,
//   lng: ,
//   name: "",
//   googleMapLink: "",
//   googleMapEmbedLink: "",
//   chineseName: "",
//   color: "",
//   expire:undefined,
//   activityLink: "",
//   attention: ""
// },

export const PTEData = () => {
  return {
    initConfig: {},
    data: [
      {
        id: "061001",
        type: "ro",
        title: "Indian IT",
        content: `(1) 创新在印度不仅仅源于IT技能，也与创业精神有关，印度波士顿咨询集团主席阿伦·玛丽亚表示。
(2) 印度商人利用IT创造了新的商业模式，以更经济的方式提供服务。
(3) 这并不一定需要昂贵的技术研究。
(4) 如果本地能力不足，他建议国内的计算机服务行业可以将研究外包给外国大学。
(5) 玛丽亚先生表示，这样他可以在不自行培养科学家的情况下，获得全球最优秀的科学家资源。
`,
      },
      {
        id: "061002",
        type: "ro",
        title: "Foreign aid",
        content: `(1) 但是从20世纪90年代开始，外国援助已经开始逐渐改善。
(2) 新闻媒体的审查使许多发达国家感到羞愧，从而限制了他们的不良做法。
(3) 如今，世界银行等组织的项目受到监督团体的严格审查。
(4) 尽管体制远未达到完美，但与外国援助常常帮助残暴的独裁者维持权力的情况相比，现在它无疑更加透明。
`,
      },
      {
        id: "061003",
        type: "ro",
        title: "Jet Stream",
        content: `(1) 滚流，是位于地球表面以上约7至8英里（11.3-12.9公里）高度的狭窄、迅速的空气流动区域，或空气管道。
(2) 它们是由相邻气团之间的温度差异引起的。
(3) 滚流不是沿着直线移动，而是呈波浪状流动；这些波动以比风速本身慢得多的速度向东传播（在北半球）。
(4) 由于飞机的前进受到尾风或顶风的影响，这会帮助或阻碍飞机的前进。
(5) 在北半球，飞机朝东飞行时会寻找滚流，以获得速度并节省燃料，而朝西飞行的飞机则会避开滚流。
`,
      },
      {
        id: "061004",
        type: "ro",
        title: "Map",
        content: `(1) 在我记忆中的很长一段时间里，皮卡迪利圆环地铁站的候车大厅里一直有一幅地图，据说显示了世界各时区的昼夜变化。
(2) 这有些令人惊讶，考虑到伦敦地铁在历史上难以理解准时概念的困难。
(3) 但是这张地图一直让我着迷，现在仍然如此，尽管它现在看起来非常原始。
(4) 这是因为它根据经度均等地划分了世界，而没有考虑政治划分或季节变化的实际情况。
`,
      },
      {
        id: "061005",
        type: "ro",
        title: "Chimpanzees",
        content: `(1) 反驳这个假设（先天假说）的一个简单方法是证明其他物种有能力说话，但由于某种原因只是没有发展出言语。
(2) 一个合乎逻辑的物种候选人是黑猩猩，它与人类的基因编码有98.4%的相似之处。
(3) 黑猩猩无法说话，因为与智人不同，它们的声带位于喉部较高的位置，无法像人类的声带那样精细地控制。
(4) 然而，从它们缺乏语言能力并不意味着黑猩猩不具备语言能力。
(5) 也许如果它们能用其他方式表达，就像人类一样，它们可以像人类一样掌握语法。`,
      },
      {
        id: "061006",
        type: "ro",
        title: "Immigration Effect",
        content: `(1) 在21世纪初，移民对福利国家的影响，特别是福利国家吸收大量移民的能力，已成为政策制定者和政治家之间讨论的常见话题。
(2) 这也是新闻界的一个反复出现的主题，从高端杂志《前景》的页面到《每日邮报》的民粹主义报道。
(3) 这些讨论必然集中在当今的困境上。
(4) 但这些问题本身并不新，它们有着比已经被承认的更为深远的历史根源。`,
      },
    ],
  };
};
