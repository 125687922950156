import { PTEData } from "./DataSource";
import "./App.css";
import {
  List,
  Breadcrumb,
  Button,
  Icon,
  Divider,
  Label,
} from "semantic-ui-react";
import { useEffect, useState } from "react";


function App() {


  const [currentMenu, setCurrentMenu] = useState("main")
  
  const { data } = PTEData()
    const [availableVoices, setAvailableVoices] = useState();
  const [selectedVoice, setSelectedVoice] = useState()
const synth = window.speechSynthesis;

  
  function setSpeech() {
    return new Promise(function (resolve, reject) {
    
      let id;

      id = setInterval(() => {
        if (synth?.getVoices().length !== 0) {
          resolve(synth?.getVoices());
          clearInterval(id);
        }
      }, 10);
    });
  }


  setSpeech().then((voices) => setAvailableVoices(voices));


  useEffect(() => {
    setSelectedVoice(
      availableVoices?.find((voice) => voice.lang.toString() === "zh-CN")
    );
  }, [availableVoices]);

  

  const types = [...new Set(data.flatMap(({ type }) => type))];
  
 
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Section
          link
          onClick={() => {
            setCurrentMenu("main");
          }}
        >
          Home
        </Breadcrumb.Section>
        {currentMenu !== "main" && (
          <>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>{currentMenu.toUpperCase()}</Breadcrumb.Section>
          </>
        )}
      </Breadcrumb>
      <Divider />
      {synth?.speaking && synth?.paused && (
        <Button
          icon
          labelPosition="left"
          onClick={() => {
            synth?.resume();
          }}
        >
          <Icon name="play" />
          Play
        </Button>
      )}
      {synth?.speaking && !synth?.paused && (
        <Button
          icon
          labelPosition="left"
          onClick={() => {
            synth?.pause();
          }}
        >
          <Icon name="pause" />
          Pause
        </Button>
      )}
      {synth?.speaking && (
        <Button
          icon
          labelPosition="left"
          onClick={() => {
            synth?.cancel();
          }}
        >
          <Icon name="stop" />
          Stop
        </Button>
      )}
      {currentMenu === "main" && (
        <List divided relaxed>
          {types.map((el) => (
            <List.Item
              key={el}
              onClick={() => {
                setCurrentMenu(el);
              }}
            >
              <List.Icon
                name="angle right"
                size="large"
                verticalAlign="middle"
              />
              <List.Content>
                <List.Header as="a">{el.toUpperCase()}</List.Header>
                <List.Description as="a">
                  <Label>{data.filter(({ type }) => type === el).length}</Label>
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      )}
      <List divided relaxed>
        {data
          .filter((el) => el.type === currentMenu)
          .map((el) => (
            <List.Item
              disabled={!selectedVoice}
              key={el.id}
              onClick={() => {
                if (synth?.speaking) {
                  synth?.cancel();
                }
                const ins = new SpeechSynthesisUtterance(
                  el.title + " " + el.content
                );
                ins.voice = selectedVoice;
                synth?.speak(ins);
              }}
            >
              <List.Icon
                name="angle double right"
                size="large"
                verticalAlign="middle"
              />
              <List.Content>
                <List.Header as="a">{el.title}</List.Header>
                <List.Description as="a">{el.id}</List.Description>
              </List.Content>
            </List.Item>
          ))}
      </List>
    </div>
  );
}

export default App;
